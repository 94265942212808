import React from 'react';
import { useDispatch } from 'react-redux';
import {Routes, Route} from 'react-router-dom'
import FullCard from './components/Card/FullCard';
import MainLayout from './components/layouts/MainLayout/MainLayout';
import { Register } from './components/layouts/RegisterLayout/RegisterLayout';
import SellerLayout from './components/layouts/SellerLKLayout/SellerLayout';
import CardInfo from './components/UserPage/CardInfo';
import MainPage from './pages/MainPage';
import {  fetchAuthMe } from './redux/slices/auth';
import GlobalStyles from './styles/GlobalStyles';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import UserRouteCheckPage from './components/user-route-check-page/user-route-check-page';
import PrivacyPolicy from './components/information-pages/privacy-policy';
import TermOfUse from './components/information-pages/term-of-use';
import Recomendation from './components/information-pages/recomendation';
import OurHistory from './components/information-pages/our-history';
import MastersPage from './components/masters-page/Masters-page';
import News from './components/news/news';
import OnlineShowParticipant from './components/online-show/online-show-participant/online-show-participant'
import Partners from './components/partners/partners';
import OnlineShow from './components/online-show/online-show';

function App() {

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchAuthMe())
  }, [])

  return (
    <>
    <PayPalScriptProvider options={{ "client-id": "AXRUuV00LdpvaleCVaLY-mJNoqdAXjiSQ_IXN37p3wFNZvrhAM21ujVMFZaONSBZVLFMxaloyCmcL913" }}>
    <GlobalStyles />
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<MainPage />} />
        <Route path="card/:id" element={<FullCard />} />
        <Route path="auth/*" element={<Register />} />
        <Route path="user/:id" element={<CardInfo />} />
        <Route path="account/*" element={<SellerLayout />} />
        {/* <Route path="*" element={<MissingPage />} /> */}
        <Route path="*" element={<UserRouteCheckPage/>} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
        <Route path="/termsOfUse" element={<TermOfUse/>} />
        <Route path="/masters" element={<MastersPage/>} />
        <Route path="/recomendation" element={<Recomendation />} />
        <Route path="/ourHistory" element={<OurHistory />} />
        <Route path="/show_participant" element={<OnlineShowParticipant />} />
        <Route path="/show" element={<OnlineShow />} />
        <Route path="/news" element={<News />} />
        <Route path="/ourPartners" element={<Partners />} />
      </Route>
    </Routes>
    </PayPalScriptProvider>
    </>
  );
}

export default App;
