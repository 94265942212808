import styled from 'styled-components/macro'
import closeSVG from './../MessageModal/close.svg';

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
`
const Modal = styled.div`
    font-family: 'Montserrat';
    background: #FFFFFF;
    padding: 30px 0 70px;
    border-radius: 10px;
    width: 600px;

    @media (max-width: 577px){
        padding: 18px 0 38px;
        width: 264px;
    }
`

const ModalTop = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;
    padding: 0 45px;

    @media (max-width: 577px){
        padding: 0 15px;
    }
`

const ModalCloseBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: 577px){
        img {
            width: 10px;
        }
    }
`

const ModalTitle = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    opacity: 0.8;
    margin-bottom: 33px;
    text-align: center;
    @media (max-width: 577px){
        font-size: 10px;
        line-height: 13px;
        margin-bottom: 11px;
    }
`

const ModalMain = styled.div`
   padding: 27px 74px 0;

   @media (max-width: 577px){
        padding: 30px 21px 0;
    }
`

const Btn = styled.button`
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    border: none;
    height: 52px;
    opacity: 0.8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 577px){
        padding: 9px 14px;
        font-size: 10px;
        line-height: 13px;
        height: 29px;
        width: 71px;
    }
`

const AcceptBtn = styled(Btn)`
    background-color: #FFE7D9;
    
`

const CancelBtn = styled(Btn)`
    border: 1px solid #949494;
    background: none;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 21px;
`
const ConfirmModal = ({onConfirm, onClose, title ,acceptBtn, cancelBtn}) => {
    return (
        <ModalWrapper>
            <Modal>
                <ModalTop>
                    <ModalCloseBtn onClick={onClose}>
                        <img alt='close' src={closeSVG} />
                    </ModalCloseBtn>
                </ModalTop>
                <ModalMain>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                    <ButtonWrapper>
                        <AcceptBtn onClick={onConfirm}>{acceptBtn}</AcceptBtn>
                        <CancelBtn onClick={onClose}>{cancelBtn}</CancelBtn>
                    </ButtonWrapper>
                </ModalMain>
            </Modal>
        </ModalWrapper>
    )
}

export default ConfirmModal;