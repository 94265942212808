import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, MainTextTitle, ShowListTitle, ShowList, ShowListItem, ShowListSmall, OurBenefits, ShowListBenefitItem, ShowByeTitle,
    OnlineShowTitle,  OnlineShowSubTitle, ShowListItemGreen,
    WinnersTitle,
    TestGradText,
    WinnersTitleMobile,
    WinnersIcon,
    TestGradTextSecond, ButtonSend
} from './styles';
import { MarketPart } from './online-show-works/styles';
import winIcon from './online-show-finish/assets/win-icon.png'
import naturalWinner from './online-show-finish/assets/natural-look.png'
import adorableWinner from './online-show-finish/assets/adorable-winner.png'
import originalWinner from './online-show-finish/assets/original-winner.png'
import creativeWinner from './online-show-finish/assets/creative-winner.png'
import WinnerCard from './online-show-finish/winner-card/winner-card';
import fantasyWinner from './online-show-finish/assets/fantasy-look.png'
import choiceWinnerIlka from './online-show-finish/assets/choice-IlkaIMG_6772 1.png'
import choiceWinnerToobears from './online-show-finish/assets/choice-toobears.png'
import { useState } from 'react';
import ModalFeedBack from '../../ui-kit/modal-feed-back/modal-feed-back';
import stars from './assets/stars.png';

const OnlineShow = () => {
    const { t } = useTranslation();
    const [isModalShow, setIsModalShow] = useState(false);

    return (
        <Root>
            <BannerBear></BannerBear>
            <OnlineShowTitle>{t('OnlineShowTitle')}</OnlineShowTitle>
            <OnlineShowSubTitle>{t('OnlineShowSubTitle')}</OnlineShowSubTitle>
            <MainText>
                <MainTextTitle><i>{t('ToobearShow')} </i>- </MainTextTitle>
                {t('ToobearShowDesc')}
            </MainText>
            <MainText>{t('ShowDesc2')}</MainText>
            <ShowListTitle><i>{t('ShowRules')}</i></ShowListTitle>
            <ShowList>
                <ShowListItem><i><b>{t('ShowItem1/2')}</b></i>{t('ShowItem2/2')}</ShowListItem>
                <ShowListItem>{t('ShowItem2-1/2')}<ShowListItemGreen><i><b>{t('ShowItem2-2/2')}</b></i></ShowListItemGreen></ShowListItem>
                <ShowListItem>{t('ShowItem3')}</ShowListItem>
                <ShowListItem>{t('ShowItem4')}</ShowListItem>
                <ShowListItem>{t('ShowItemPrice')}</ShowListItem>
                <ShowListItem><b><i>{t('ShowItem5-1/2')}</i></b>{t('ShowItem5-2/2')}</ShowListItem>
                <ShowListItem>{t('ShowItemPhotoRules')}</ShowListItem>
                <ShowListItem>{t('ShowItem6/1')}</ShowListItem>
                <ShowListItem>{t('ShowItem6')}</ShowListItem>
                <ShowListItem>{t('ShowItem8')}</ShowListItem>
                <ShowListItem>{t('ShowItem9-1/2')}<b><i>{t('ShowItem9-2/2')}</i></b></ShowListItem>
                <ShowListSmall>
                    <ShowListItem>{t('ShowItemSmall1')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall2')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall3')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall4')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall5')}</ShowListItem>
                    <ShowListItem>{t('ShowItemSmall6')}</ShowListItem>

                </ShowListSmall>
                <ShowListItem>{t('ShowItem10-1/3')}<b><i>{t('ShowItem10-2/3')}</i></b>{t('ShowItem10-3/3')}</ShowListItem>
                <ShowListItem>{t('ShowItem11')}</ShowListItem>
            </ShowList>
            <WinnersTitle>
                <TestGradText>
                    {t('PreviousWinners')}<br/>
                    <TestGradTextSecond>{t('WinnerShowTitle2')}</TestGradTextSecond>
                </TestGradText>
            </WinnersTitle>
            <WinnersTitleMobile>
                <TestGradText>{t('WinnerShowTitleMobile1')}</TestGradText>
                <br/><TestGradTextSecond>{t('WinnerShowTitleMobile2')}</TestGradTextSecond>
            </WinnersTitleMobile>
                <MarketPart>
                    <WinnerCard
                        nomination={<>The most natural <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={naturalWinner}
                        award={t('prizeExclusive')}
                        name="Marquis the Cat"
                        author="Elena Boyko"
                        authorLink="/theworldoflittleanimals"
                        cardLink="/card/66daa17a033665ef0e94342f"
                        nominationMobile={<>The most natural look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>The most fantasy <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={fantasyWinner}
                        award={t('prizeExclusive')}
                        name="Forest Beau Monde"
                        author="Julia Melnikova"
                        authorLink="/workshopofyuliamelnikova"
                        cardLink="card/66da97ec6fd69073009a3668"
                        nominationMobile={<>The most fantasy look<WinnersIcon alt='win' src={winIcon}/></>}
                    />
                        <WinnerCard
                        nomination={<>The most original <WinnersIcon alt='win' src={winIcon}/> <br/>composition</>}
                        img={originalWinner}
                        award={t('prizeExclusive')}
                        name="Scary baby teddy owl Boo"
                        author="Ekaterina Nebykova"
                        authorLink="/ILKAteddy"
                        cardLink="/card/66da9923033665ef0e9422b3"
                        nominationMobile={<>The most original <WinnersIcon alt='win' src={winIcon}/> <br/>composition</>}
                    />
                    <WinnerCard
                        nomination={<>The most adorable bear<WinnersIcon alt='win' src={winIcon}/> <br/>of the show</>}
                        award={t('prizeExclusive')}
                        img={adorableWinner}
                        name="Lars and Quentin"
                        author="Nattie Kelly"
                        authorLink="/nattiesteddies"
                        cardLink="/card/66daa0f6033665ef0e943354"
                        nominationMobile={<>The most adorable bear<WinnersIcon alt='win' src={winIcon}/> <br/>of the show</>}
                    />
                    <WinnerCard
                        nomination={<>The most creative <WinnersIcon alt='win' src={winIcon}/> <br/>look</>}
                        img={creativeWinner}
                        award={t('prizeExclusive')}
                        name="Eva"
                        author="Rita Shesholko"
                        authorLink="/feyushkarita"
                        cardLink="/card/66da9a95033665ef0e94256c"
                        nominationMobile={<>The most creative look <WinnersIcon alt='win' src={winIcon}/></>}
                    />
                    <WinnerCard
                        nomination={<>Toobears Choise from <WinnersIcon alt='win' src={winIcon}/> <br/>Toobears</>}
                        img={choiceWinnerToobears}
                        award={<>{t('prizeNextShow')}<br/> {t('prizeNextShow2')}</>}
                        name="Maned wolf"
                        author="Natalya Vasileva"
                        authorLink="/Arleana_family"
                        cardLink="/card/66dace91a6ab2bcde9bc1bed"
                        nominationMobile={<>Toobears Choise from<WinnersIcon alt='win' src={winIcon}/><br/>Toobears</>}
                    />
                    <WinnerCard
                        nomination={<>Toobears Choise from a <WinnersIcon alt='win' src={winIcon}/> <br/>partner Ilka_Bukinist</>}
                        img={choiceWinnerIlka}
                        award={t('prizeBook')}
                        name="Witcher bear"
                        author="Maria Makhovskaya"
                        authorLink="/maryandbears"
                        cardLink="/card/66dabd34a6ab2bcde9bbd55c"
                        nominationMobile={<>Toobears Choise from a<WinnersIcon alt='win' src={winIcon}/> <br/>partner Ilka_Bukinist</>}
                    />
                </MarketPart>
            <OurBenefits>
                <ShowListTitle><i>{t('OurBenefits')}</i></ShowListTitle>
                <ShowListSmall>
                    <ShowListBenefitItem>{t('OurBenefitsItem1')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem2')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem2/1')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem3')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem4')}</ShowListBenefitItem>
                    <ShowListBenefitItem>{t('OurBenefitsItem5')}</ShowListBenefitItem>
                </ShowListSmall>
            </OurBenefits>
            <ButtonSend onClick={() => setIsModalShow(true)}>{t('TakePart')}</ButtonSend>
            {isModalShow && 
                <ModalFeedBack 
                    image={stars} 
                    title={t('TitleTakePart')} 
                    text={t('TextTakePart')} 
                    onClose={() => setIsModalShow(false)}
                    url={'show-request'}
                />
            }
        </Root>
    )
}

export default OnlineShow;